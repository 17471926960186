import React, { useState, useEffect } from 'react';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import '../styles/sideBar.css';
import booby from '../../images/booby.png'
import { Link, NavLink } from 'react-router-dom'; 
import { SplashScreen } from '../../actions/splashScreen';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector, useDispatch } from 'react-redux';

export default function AccountMenu({ activeChildMenu }) {
    const [activeMenu, setActiveMenu] = useState(activeChildMenu);
    const SplashScreenReducer = useSelector(state => state.SplashScreenReducer);

    var toggleClick = () => {
        var sidebarToggled = document.querySelectorAll(".pro-sidebar:not(.toggled)");
        var appWrapper = document.getElementsByClassName("app-wrapper");
        if (sidebarToggled && sidebarToggled.length > 0) {
            appWrapper[0].classList.add("toggled");
        } else {
            appWrapper[0].classList.remove("toggled");

        }
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            var appWrapper = document.getElementsByClassName("app-wrapper");
            appWrapper.length > 0 && appWrapper[0].classList.remove("toggled");
        });
    }, [])

    return (
        <div className='bg-dark app-sidebar' style={{ display: 'flex', overflow: 'scroll initial' }}>
            <CDBSidebar backgroundColor="#F6F6F6" textColor="#7E8299" maxWidth="220px">
                <CDBSidebarHeader prefix={<i onClick={toggleClick} className="fa fa-bars fa-large"></i>}>

                    <Link to="/index" className="text-decoration-none" style={{ color: 'inherit', textAlign: 'center' }}>
                        <img src={booby} alt="brand" width={135} ></img>
                    </Link>
                </CDBSidebarHeader>

                <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>
                        <NavLink exact="true" to="/account/generalProfile" activeclassname="active">
                            <CDBSidebarMenuItem style={{ backgroundColor: 'inherit' }} icon="user">General</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink exact="true" to="/account/uploadeds" activeclassname="active">
                            <CDBSidebarMenuItem style={{ backgroundColor: 'inherit' }} icon="file-lines">Uploads</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink exact="true" to="/account/history" activeclassname="active">
                            <CDBSidebarMenuItem style={{ backgroundColor: 'inherit' }} icon="book">History</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink exact="true" to="/account/invoices" activeclassname="active">
                            <CDBSidebarMenuItem style={{ backgroundColor: 'inherit' }} icon="book-journal-whills">Invoices</CDBSidebarMenuItem>
                        </NavLink>

                    </CDBSidebarMenu>
                </CDBSidebarContent>

    
            </CDBSidebar>
        </div> 
    )
}