import React, { Component, useEffect, useState } from 'react';
import '../styles/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { EditUserAction, EditUserWalletAction } from '../../actions/editUserAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import AccountMenu from './accountMenu';
import UserImage from './userImage';
import { getUserInformation } from '../../actions/getUserInformation';
import { ChangePasswordInsideAction } from '../../actions/changePasswordInsideAction';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { ForgetPasswordAction } from '../../actions/forgetPassword';
//import CountrySelector from './country-select';

export default function GeneralProfile({ props }) {
    
    const location = useLocation();
    //const [userImage, setUserImage] = useState(location.state && (location.state.changePicture === true ? true : false));
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const dispatch = useDispatch();
    const { testvalue } = useParams();
    var userData = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const [userName, setUsername] = useState(userData.userName);
    const [lastName, setlastName] = useState(userData.lastName);
    const [firstName, setfirstName] = useState(userData.firstName);

    const [walletAddress, setWalletAddress] = useState(userData.ethWalletAddress);

    const [email, setEmail] = useState(userData.email);
    const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber);
    const EditUserReducer = useSelector(state => state.EditUserReducer);

    const EditUserWalletReducer = useSelector(s => s.EditUserWalletReducer);

    const [country, setCountry] = useState(userData.country);
    const [region, setRegion] = useState('');
    const getUserInformationReducer = useSelector(state => state.getUserInformationReducer);
    const ChangePasswordInsideReducer = useSelector(state => state.ChangePasswordInsideReducer);
    const forgetPasswordRedux = useSelector(state => state.forgetPasswordRedux)
    const [input, setInput] = useState({
        newPassword: '',
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState({
        newPassword: '',
        password: '',
        confirmPassword: '',
    });

    const onChangeuserName = (event) => {
        event.preventDefault();
        setUsername(event.target.value);
    }

    useEffect(() => {
        if (getUserInformationReducer && getUserInformationReducer.data && getUserInformationReducer.data.isSuccessful) {
            localStorage.setItem('user', JSON.stringify(getUserInformationReducer.data.data));

        }
    }, [getUserInformationReducer])

    useEffect(() => {
        if (EditUserReducer && EditUserReducer.data && EditUserReducer.data.isSuccessful === true) {
            dispatch(getUserInformation());
        }
    }, [EditUserReducer]);

    useEffect(() => {
        if (EditUserWalletReducer && EditUserWalletReducer.data && EditUserWalletReducer.data.isSuccessful === true) {
            dispatch(getUserInformation());
        }
    }, [EditUserWalletReducer]);

    const onChangeEmail = (event) => {
        event.preventDefault();
        setEmail(event.target.value);
    }

    const onChangefirstName = (event) => {
        event.preventDefault();
        setfirstName(event.target.value);
    }

    const onChangeWalletAddress = (event) => {
        event.preventDefault();
        setWalletAddress(event.target.value);
    }

    const onChangelastName = (event) => {
        event.preventDefault();
        setlastName(event.target.value);
    }

    const onChangePhoneNumber = (event) => {
        event.preventDefault();
        setPhoneNumber(event.target.value);
    }

    const onClickEditUser = (event) => {
        event.preventDefault();
        dispatch(EditUserAction(email, userName, phoneNumber, firstName, lastName, country));
    }

    const onClickEditWalletAddress = (event) => {
        event.preventDefault();
        dispatch(EditUserWalletAction(walletAddress));
    }

    const onClickChangePassword = (event) => {
        event.preventDefault();
        
        if (isValidPassword(input.newPassword)) {
            dispatch(ChangePasswordInsideAction(input.newPassword, input.password));
        } 
    }

    const selectCountry = (val) => {
        setCountry(val);
    }

    const InputChange = (event) => {
        const { name, value } = event.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(event) 
    }
    function isValidPassword(password) {
        const passwordInputValue = password;

        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;

        const passwordLength = passwordInputValue.length;
        const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
        const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
        const digitsPassword = digitsRegExp.test(passwordInputValue);
        const specialCharPassword = specialCharRegExp.test(passwordInputValue);
        const minLengthPassword = minLengthRegExp.test(passwordInputValue);

        if (passwordLength === 0) {
            setError(prev => {
                const stateObj = { ...prev, ["newPassword"]: "Password is empty" }
                return stateObj;
            })
            return false;
        } else if (!uppercasePassword) {
            setError(prev => {
                const stateObj = { ...prev, ["newPassword"]: "At least one Uppercase['A']-['Z']" }
                return stateObj;
            })
            //document.getElementsByName('password').style.borderColor = 'red';
            return false;
        } else if (!lowercasePassword) {
            setError(prev => {
                const stateObj = { ...prev, ["newPassword"]: "At least one Lowercase" }
                return stateObj;
            })
            return false;
        } else if (!digitsPassword) {
            setError(prev => {
                const stateObj = { ...prev, ["newPassword"]: "At least one digit" }
                return stateObj;
            })
            return false;
        } else if (!specialCharPassword) {
            setError(prev => {
                const stateObj = { ...prev, ["newPassword"]: "At least one Special Characters" }
                return stateObj;
            })
            return false;
        } else if (!minLengthPassword) {
            setError(prev => {
                const stateObj = { ...prev, ["newPassword"]: "At least minumum 8 characters" }
                return stateObj;
            })
            return false;
        }
        //document.getElementsByName('password').style.borderColor = 'green';
        return true;
    }

    useEffect(() => {
        dispatch(ForgetPasswordAction());
    },[])
    const forgetPassword = (event) => {
        event.preventDefault();
        dispatch(ForgetPasswordAction(email));
    }

    useEffect(() => {
        if (forgetPasswordRedux && forgetPasswordRedux.data !== null && forgetPasswordRedux.error === undefined && forgetPasswordRedux.data.isSuccessful === true) {
            navigate('/resetPasswordEmail');
        }
    }, [forgetPasswordRedux])

    const validateInput = (event) => {
        let { name, value } = event.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "newPassword":
                    if (!value) {
                        stateObj[name] = "Please enter new password.";
                    } 
                    break;
                case "password":
                    if (!value) {
                        stateObj[name] = "Please enter password.";
                    }
                    break;
                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please enter confirm password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Current Password and Confirm Password does not match.";
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        })
    }

    return (  
<div style={{display: 'flex'}}>
            <ToastContainer />
            <div className="section mainDivRsT"  style={{ width: '100%', minHeight: '100vh'}}>
                
                <AccountMenu activeChildMenu="General"/>
                <div className="p-3 w-100 app-wrapper">   
                <div className="pb-8 pb-md-11 mt-md-n6">
                    <div className="container-md">
                        <div className="row">
                        </div>
                            <div className="col-12 p-3">
                                <div>
                                    <div className="col-12 col-lg-12">


                                        <div className="card card-bleed shadow-light-lg mb-5">
                                            <div className="Profiletitle">
                                                <h4 className="pt-2 pe-2 ps-2 fw-bold">Basic Information</h4>
                                                <hr></hr>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-md-5 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Firstname</label>
                                                            <input className="form-control" id="firstName" type="text" onChange={onChangefirstName} required placeholder="First name" value={firstName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="lastName">Lastname</label>
                                                            <input className="form-control" onChange={onChangelastName} id="lastName" type="text" required value={lastName} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-5 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="name">UserName</label>
                                                            <input className="form-control" id="name" type="text" onChange={onChangeuserName} required placeholder="Full name" value={userName} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-3">
                                                        <div className="form-group">
                                                            <label htmlFor="email">Email</label>
                                                            <input className="form-control" onChange={onChangeEmail}  id="email" type="email" required value={email} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-5">
                                                        <div className="form-group">
                        <label for="email">Phone Number</label>
                                                            <input className="form-control" id="phoneNumber" onChange={onChangePhoneNumber} type="text" value={phoneNumber} />
                    </div>
                                                    </div>
                                                    <div className="col-12 col-md-5 mb-3">
                                                        <div className="form-group">
                                                            <label for="country">Country</label>
                                                            <div>
                                                                <CountryDropdown
                                                                    className="form-control"
                                                                    value={country}
                                                                    onChange={(val) => selectCountry(val)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                <div className='mt-3 w-75'>
                                                        <button className="btn btn-spf-grn" onClick={onClickEditUser} disabled="">Save changes</button>
                </div>
            </div>
        </div>
                                        </div>


                                        <div className="card card-bleed shadow-light-lg mb-5">
                                            <div className="Profiletitle">
                                                <h4 className="pt-2 pe-2 ps-2 fw-bold">Web3 Wallet Information</h4>
                                            </div>
                                            <hr />
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-12 col-md-5 mb-3">
                                                        <small>
                                                        
                                                        </small>
                                                        <div className="form-group">
                                                            <label htmlFor="firstName">Polygon MATIC Wallet Address</label>
                                                            <input className="form-control" id="walletAddress" type="text" onChange={onChangeWalletAddress} required placeholder="0x...." value={walletAddress} />
                                                        </div>

                                                        <div className='mt-3 w-75'>
                                                            <button className="btn btn-spf-grn" onClick={onClickEditWalletAddress} disabled="">Save Wallet Address</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="card card-bleed shadow-light-lg mb-5">
                                            <div className="">
                                                <div className="row align-items-center">
                                                    <div className="col d-flex Profiletitle">
                                                        <h4 className="pt-2 pe-2 ps-2 fw-bold">Password</h4>
                                                        <div className="col-auto">
                                                            <Link className="small text-gray-700 p-3" state={{ email: email }} onClick={forgetPassword} >
                                                                Forgot your password?
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                            </div> 
                                            <div className="card-body">
                                                <div className="form-group mb-3">
                                                    <label  for="currentPassword">Current Password</label>
                                                    <input className="form-control" value={input.password} onChange={InputChange}  name="password" id="currentPassword" type="password" />
                                                    {error && error.password && <span className='errMsg'> - {error.password}</span>}
                                                </div>
                                                
                                                <div className="form-group mb-3">
                                                    <label for="confirmPassword">Confirm Password</label>
                                                    <input className="form-control" value={input.confirmPassword} onChange={InputChange} name="confirmPassword" id="confirmPassword" type="password" />
                                                    {error && error.confirmPassword && <span className='errMsg'> - {error.confirmPassword}</span>}
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label for="newPassword">New Password</label>
                                                    <input className="form-control" value={input.newPassword} onChange={InputChange} name="newPassword" id="newPassword" type="password" />
                                                    {error && error.newPassword && <span className='errMsg'> - {error.newPassword}</span>}
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-auto mt-3">
                                                        <button className="btn btn-block btn-spf-grn" onClick={onClickChangePassword} disabled="">Update Password</button>
            </div>
        </div>
    </div>
</div>
</div>
</div>
                </div>
            </div>
        </div> 
    </div>
            </div>
            {(ShowModalFlagReducer && ShowModalFlagReducer.show === true && ShowModalFlagReducer.data === true) && <UserImage show="true" />}
    </div>


        )
}
/*const selectRegion = (val) => {
        setRegion(val);
}*/
/*
    <div className="col-12 col-md-5 mb-3">
    <div className="form-group">
        <label for="Region">Region</label>
        <div>
            <RegionDropdown
                className="form-control"
                country={country}
                value={region}
                onChange={(val) => selectRegion(val)} />
        </div>
    </div>
</div>    
*/