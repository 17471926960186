const initialState = {
    data: null,
};
const forgetPasswordRedux = (state = null, action) => {
    switch (action.type) {
        case 'FORGETPASSWORD_SUCCESS':
            return {
                ...state,
                data: action.payload,
            };
        case 'FORGETPASSWORD_FAILURE':
            return {
                ...state,
                error: action.payload
            };
        case 'FORGETPASSWORD_RESET':
            return initialState;
        default:
            return state;
    }
}

export default forgetPasswordRedux;