import React, { Component, useEffect } from 'react';
import '../styles/login.css';
import booby from '../../images/Logo_removebg.png';
export function ResetPasswordEmail() {

    return (
        <div className="row AuthTL">
            <section className="col-md-4">
                <form>
                    <p style={{ textAlign: 'center', fontSize: '18px' }}>Please check your email to reset your password.</p>
                </form>
            </section>
        </div>
    );

}
