import React, { Component, useEffect, useState, useMemo } from 'react';
import '../styles/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { GetSharedFromMeAll } from '../../actions/getSharedFromMeAll';
import DataTable, { createTheme } from "react-data-table-component";
import FilterComponent from './filterComponent';
import AccountMenu from './accountMenu';
export default function Uploadeds() {
    const dispatch = useDispatch();
    const { testvalue } = useParams();
    var userData = JSON.parse(localStorage.getItem('user'));
    const getSharedFromMeAllReducer = useSelector(state => state.GetSharedFromMeAllReducer);
    const [data, setData] = useState([]);
    const [dataFlag, setDataFlag] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    useEffect(() => {
        dispatch(GetSharedFromMeAll());
    },[])

    useEffect(() => {
        if (getSharedFromMeAllReducer && getSharedFromMeAllReducer.data && getSharedFromMeAllReducer.data.isSuccessful === true) {
            setData(getSharedFromMeAllReducer.data.data);
            setDataFlag(true);
        }
    }, [getSharedFromMeAllReducer]);

    const columns = [
        {
            name: "Name",
            selector: (row) => row.document['name'],
            sortable: true,
        },
        {
            name: "Description",
            selector: (row) => row.document['description'],
            sortable: true
        },
        {
            name: "Reader",
            selector: (row) => row.readedBy,
            sortable: true
        }, {
            name: "Shared At",
            selector: (row) => row.createdAtRes.split('.')[0].replace('T', ' '),
            sortable: true
        },
        {
            name: "Expire At",
            selector: (row) => row.createdAtRes.split('.')[0].replace('T', ' '),
            sortable: true
        },
        {
            name: "View",
            selector: (row) => (
                <div className="btn-group-actions">
                    <Link className="text-success btn btn-sm btn-success text-white" to={{
                        pathname: `/index/LinkEntry/${row.viewKey}`,
                    }} state={{ viewkey: row.viewKey, id: row.document.id, type: "LinkEntry" }} title="viewKey">
                        <i style={{ color: 'white' }} className="fa-regular fa-eye"></i>
                    </Link>
                </div>
            ),
        },

    ];

    const filteredItems = data.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <h4 className="me-auto" style={{ color: 'black' }}>Shared PDF</h4>
                <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </>
        );
    }, [filterText, resetPaginationToggle]);

    const customPagination = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "All",
    }
    const tableCustomStyles = {

        headRow: {
            style: {
                color: '#223336',
                backgroundColor: '#e7eef0'
            },
        },
    }
    return (
        <div style={{ display: 'flex' }}>

            <div className="section mainDivRsT" style={{ width: '100%', minHeight: '100vh' }}>
                <ToastContainer />
                <AccountMenu activeChildMenu="Uploads" />
                <div className="p-5 w-100 app-wrapper">
                    <div className="pb-8 pb-md-11 mt-md-n6">
                        <div className="container-md">
                            {dataFlag &&
                                <div className="TableShared">
                                    <DataTable
                                        columns={columns}
                                        customStyles={tableCustomStyles}
                                        data={filteredItems}
                                        pagination
                                        paginationComponentOptions={customPagination}
                                        highlightOnHover
                                        striped
                                        defaultSortFieldId={1}
                                        defaultSortOrder="asc"
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
