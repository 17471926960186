import React, { Component, useEffect, useState, useMemo } from 'react';
import '../styles/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { GetSharedFromMeAll } from '../../actions/getSharedFromMeAll';
import DataTable, { createTheme } from "react-data-table-component";
import FilterComponent from './filterComponent';
import { GetUserLedger } from '../../actions/getUserLedger';
import AccountMenu from './accountMenu';
export default function Invoices() {
    const dispatch = useDispatch();
    const { testvalue } = useParams();
    var userData = JSON.parse(localStorage.getItem('user'));
    const getUserLedgerReducer = useSelector(state => state.GetUserLedgerReducer);
    const [data, setData] = useState([]);
    const [dataFlag, setDataFlag] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    useEffect(() => {
        dispatch(GetUserLedger());
    }, [])

    useEffect(() => {
        if (getUserLedgerReducer && getUserLedgerReducer.data && getUserLedgerReducer.data.isSuccessful === true) {
            let newArray = getUserLedgerReducer.data.data.filter(function (item) {
                return item.amount > 0;
            });
            setData(newArray);
            setDataFlag(true);
        }
    }, [getUserLedgerReducer]);

    const columns = [
        {
            name: "Name",
            selector: (row) => row['userName'],
            sortable: true,
        },
        {
            name: "Amount",
            selector: (row) => row.amount,
            sortable: true,
        },
        {
            name: "Amount Value",
            selector: (row) => row.amountValue,
            sortable: true,
            conditionalCellStyles: [
                {
                    when: row => row.amount > 0,
                    style: {
                        color: 'green',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                    
                }
            ],
        },

        {
            name: "Amount Currency",
            selector: (row) => row.amountCurrency,
            sortable: true
        }, {
            name: "Created at",
            selector: (row) => row.createdAt.split('.')[0].replace('T', ' '),
            sortable: true
        },

    ];

    const filteredItems = data.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <h4 className="me-auto" style={{ color: 'black' }}>Invoices</h4>
                <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </>
        );
    }, [filterText, resetPaginationToggle]);

    const customPagination = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "All",
    }

    const tableCustomStyles = {

        headRow: {
            style: {
                color: '#223336',
                backgroundColor: '#e7eef0'
            },
        },
    }
    return (
        <div>

            <div className="section mainDivRsT" style={{ width: '100%', minHeight: '100vh' }}>
                <ToastContainer />
                <AccountMenu activeChildMenu="invoices" />
                <div className="p-5 w-100 app-wrapper">
                    <div className="pb-8 pb-md-11 mt-md-n6">
                        <div className="container-md">
                            {dataFlag &&
                                <div className="TableShared">
                                    <DataTable
                                        columns={columns}
                                        customStyles={tableCustomStyles}
                                        data={filteredItems}
                                        pagination
                                        paginationComponentOptions={customPagination}
                                        highlightOnHover
                                        striped
                                        defaultSortFieldId={1}
                                        defaultSortOrder="asc"
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                        conditionalRowStyles
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
