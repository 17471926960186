import React from 'react';
import styled from "styled-components";

const Input = styled.input.attrs(props => ({
  type: "text",
  size: props.small ? 5 : undefined
}))`

`;

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <>
    <Input
      id="searchPDFInput"
      type="text"
      className="form-control me-auto"
      placeholder="Search"
      value={filterText}
      onChange={onFilter}
    />
  </>
);

export default FilterComponent;