import '../styles/style.css';
import { Link } from 'react-router-dom';
import React, { useState, useMemo, useEffect } from 'react';
import DataTable, { createTheme } from "react-data-table-component";
import FilterComponent from './filterComponent';
import { getSharedWithMe } from '../../actions/getSharedWithMe';
import { useSelector, useDispatch } from 'react-redux';
import { GetDeletedByMe } from '../../actions/getDeletedByMe';
import { unDeleteDocumentAction } from '../../actions/unDeleteDocument';
import pdf from '../../images/pdf-ref2.png';
import { SelectedCheckboxAction } from '../../actions/selectedCheckboxAction';
import { UnDeleteMoreThanOne } from '../../actions/unDeleteMoreThanOne';
import { RecyclebinButtonActionFlag } from "../../actions/RecyclebinButtonActionFlag";
import { ShowModalFlag } from '../../actions/showModalFlag';
import PurgeDocumentModal from './purgeDocumentModal';
createTheme("solarized", {
    text: {
        primary: "#268bd2",
        secondary: "#2aa198"
    },
    background: {
        default: "#002b36"
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF"
    },
    divider: {
        default: "#073642"
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)"
    }
});

export default function RecycleBin() {
    const [deletedData, setDeletedData] = useState([]);
    const dispatch = useDispatch();
    const getDeletedByMeReducer = useSelector(state => state.getDeletedByMeReducer);
    const unDeleteDocumentReducer = useSelector(state => state.unDeleteDocumentReducer);
    const PurgeReducer = useSelector(state => state.PurgeReducer);
    const UnDeleteMoreThanOneReducer = useSelector(state => state.UnDeleteMoreThanOneReducer);
    const [purgeDocumentActive, setPurgeDocumentActive] = useState(false);
    const [data, setData] = useState();
    useEffect(() => {
        if (PurgeReducer && PurgeReducer.data && PurgeReducer.data.isSuccessful === true) {
            dispatch(GetDeletedByMe());
        }
    }, [PurgeReducer])

    useEffect(() => {
        if (UnDeleteMoreThanOneReducer && UnDeleteMoreThanOneReducer.data && UnDeleteMoreThanOneReducer.data.isSuccessful === true) {
            dispatch(GetDeletedByMe());
        }
    }, [UnDeleteMoreThanOneReducer])

    //const { data } = getSharedWithMeRedux;
    useEffect(() => {
        if (getDeletedByMeReducer && getDeletedByMeReducer.data && getDeletedByMeReducer.data.isSuccessful) {
            setDeletedData(getDeletedByMeReducer.data.data);
        }
    }, [getDeletedByMeReducer]);//getSharedWithMeRedux

    useEffect(() => {
        dispatch(GetDeletedByMe());
    }, []);

    const RecycleDocument = (event) => {
        event.preventDefault();
        dispatch(unDeleteDocumentAction(parseInt(event.currentTarget.id)))

    } 

    const PurgeDocument = (event) => {
        event.preventDefault();
        dispatch(ShowModalFlag());
        setPurgeDocumentActive(true);
        setData(event.currentTarget.id);
        
        //dispatch(UnDeleteMoreThanOne(event.currentTarget.id));
    }

    useEffect(() => {
        if (unDeleteDocumentReducer && unDeleteDocumentReducer.data && unDeleteDocumentReducer.data.isSuccessful) {
            dispatch(GetDeletedByMe());
        }
    }, [unDeleteDocumentReducer]);
    //<i class="fa-solid fa-file-pdf" style={{ color: 'rgb(40, 168, 226)', fontSize: '20px' }}></i>
    const columns = [
        {
            name: "Actions",
            width:'140px',
            selector: (row) => (

                <div className="btn-group-actions">
                    <Link onClick={RecycleDocument} id={row.id} style={{ backgroundColor: 'rgb(40, 168, 226)' }} className="text-danger btn btn-sm text-white m-1" to="#" title="Recycle">
                        <i className="fa-solid fa-recycle"></i>
                    </Link>
                    <Link onClick={PurgeDocument} id={row.id} style={{ backgroundColor: 'red' }} className="text-danger btn btn-sm text-white m-1" to="#" title="Purge">
                        <i className="fa-solid fa-trash"></i>
                    </Link>

                </div>
            ),
        }, {
            name: "Name",
            selector: (row) =>  row.name,
            cell: row => <div><img src={pdf} alt="pdf" width={25} height={25}></img>&nbsp;{row.name}</div>,
            sortable: true,
            minWidth: "200px",
        },
        {
            name: "Description",
            selector: (row) => 
                row.description
            ,
            width:'25%',
            sortable: true
        },
        {
            name: "Create",
            selector: (row) => row.createdAt.split('.')[0].replace('T', ' '),
            sortable: true,
            width:'180px'            
        },
        {
            name: "Update",
            selector: (row) => (
                row.modifiedAt.split('.')[0].replace('T', ' ')
            ),
            sortable: true,
            width:'180px'                        
        },


    ];

    const conditionalRowStyles = [
        {
            when: (row) => row,
            style: {
                "&:hover": {
                    backgroundColor: "green",
                    color: "white",
                    cursor: "pointer"
                }
            }
        }
        // You can also pass a callback to style for additional customization
    ];

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = deletedData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const onClickChanges = (data) => {

        dispatch(SelectedCheckboxAction(data.selectedRows));
       //dispatch(ButtonActionsFlag(data.selectedCount));
        dispatch(RecyclebinButtonActionFlag(data));
    }
    const customPagination = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "All",
    }
    const tableCustomStyles = {

        headRow: {
            style: {
                color: '#223336',
                backgroundColor: '#e7eef0'
            },
        },
    }
    return (
        <div className="App">
            <DataTable
                columns={columns}
                data={filteredItems}
                striped
                pagination
                paginationComponentOptions={customPagination}
                customStyles={tableCustomStyles}
                defaultSortFieldId={5}
                paginationPerPage={30}
                defaultSortOrder={false}
                highlightOnHover
                subHeader
                subHeaderComponent={subHeaderComponent}
                selectableRows
                selectableRowsOnClick
                onSelectedRowsChange={onClickChanges}
            />
            {purgeDocumentActive && <PurgeDocumentModal data={data} show="true" />}
        </div>
        
    );
}
//  onRowClicked={handleRowClicked}
//<div><p className='description'> {row.description} </p></div>