import axios from 'axios';

export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE';

export const DownloadFilesAction = (id) => async (dispatch) => {
    try {
        const tokenStorage = JSON.parse(localStorage.getItem('AccessToken'))
        const token = tokenStorage.data.accessToken;
        const ID = {
            ID: id,
        }
        const response = await axios.post("api/Main/GetPDFFile", ID, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,                
            },
            'responseType': "blob",
        }).then(function (response) { 
            
            const contentDisposition = response.headers['content-disposition'];
            var filename='pdf_download.pdf';
            if (contentDisposition) {
                const match = contentDisposition.match(/filename\*?=[^;]+\'\'([^;]*)/);
                filename = match ? match[1] : 'pdf_download.pdf';
            }
            //let byteArray = 
            var blob = new Blob([response.data], { type: "application/pdf" }); 
            const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });

        dispatch({
            type: DOWNLOAD_SUCCESS,
            payload: response.data,
        });
    } catch (error) {

        dispatch({
            type: DOWNLOAD_FAILURE,
            payload: error,
        });
    }
}

