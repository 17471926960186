import '../styles/style.css';
import React from 'react';
import SharedWithMe from './sharedWithMe';
import NavigationBar from './sideBarMenu';
import ButtonGroup from './buttonGroup';
import NavigationNames from './navigationNames';
import pdf from '../../images/pdf-ref2.png';
export default function SharedWithMePage() {
    return (
        <div className="mainDivRsT">
            <NavigationBar />
            <section className='app-wrapper w-100 me-3 mb-3'>
                <div className='currentMenuItem'>
                    <NavigationNames MenuName="Shared With Me*" />
                </div>

                <div style={{ width: '100%' }}>
                    <div id="sharedwithme row card borderNoneCard">
                        
                        <div className="col-md-12 card borderNoneCard mt-4">
                            <ButtonGroup typeOfButtons="sharedWithMe" />

                            
                            <div className="tab-content">
                                <div className="tab-pane active" id="RecycleBin">
                                    <SharedWithMe />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

//tab yap�s�
/*
<div className="card-header">
    <ul className="nav nav-tabs card-header-tabs" data-bs-tabs="tabs">
        <li className="nav-item">
            <a className="nav-link active" aria-current="true" data-bs-toggle="tab" href="#recycleBin">Shared With Me</a>
        </li>
    </ul>
</div>
*/

//<div className="alert alert-danger" role="alert"><strong>There are files that are pending credit. Click here to purchase credit credit</strong></div>
//  onRowClicked={handleRowClicked}