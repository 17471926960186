import React, { useEffect, useState } from 'react';
import '../styles/login.css';
import { useNavigate, Link } from "react-router-dom";
import { ForgetPasswordAction } from '../../actions/forgetPassword';
import { useDispatch, useSelector } from 'react-redux'
import booby from '../../images/Logo_removebg.png';
export function ForgetPassword(){
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const forgetPasswordRedux = useSelector(state => state.forgetPasswordRedux)
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(ForgetPasswordAction(email));    

    }
    const handleUsernameChange = (event) => {
        setEmail(event.target.value);
    };

    useEffect(() => {
        if (forgetPasswordRedux && forgetPasswordRedux.error === undefined && forgetPasswordRedux.data.isSuccessful === true) {
            navigate('/resetPasswordEmail');
        }
    }, [forgetPasswordRedux])
    return (
        <div className="row AuthTL">
            <div className='col-md-4 d-flex justify-content-center'>
                <section className="">
                    
                    <form id="forgetPassword" method="post" onSubmit={handleSubmit}>
                        <img src={booby} alt="Bymmy App" style={{ 'float': 'right', 'marginRight': '-25px' }}></img>
                        <h2 style={{ 'fontWeight': 'bold' }}>Recover</h2>
                        <hr />
                       
                        <div className="pb-3">
                            <label className="form-label"></label>
                            <input type="text" value={email} onChange={handleUsernameChange} className="form-control" autoComplete="true" aria-required="true" />
                        </div> 
                        <div className="text-center" style={{ textAlign: 'center' }}>Enter your email below to receive your password reset instructions.</div>
                        <br />
                        {forgetPasswordRedux && forgetPasswordRedux.error && <span style={{color: "red"} }>{forgetPasswordRedux.error.message}</span>}
                        <div className="pb-4">
                            <button id="login-submit" style={{ 'fontWeight': 'bold' }} type="submit" className="w-100 btn btn-lg btn-spf-grn default-col">
                            Reset My Password</button>
                        </div>   
                        <div>
                          
                            <p style={{ 'fontSize': '13px' }}>
                                Don't have an account? <Link to="/SignIn">Sign Up</Link>
                            </p>

                        </div>
                    </form>
                </section>
            </div>

        </div>
    );
    
}