import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../styles/style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useNavigate } from 'react-router-dom';
import { IndexAction } from '../../actions/IndexAction';
import { DeleteMoreThanOneDocumentAction } from '../../actions/deleteMoreThanOne';
import Rename from './rename';
import { UnDeleteMoreThanOne } from '../../actions/unDeleteMoreThanOne'
import PurgeDocumentModal from './purgeDocumentModal';
import { ShowModalFlag } from '../../actions/showModalFlag';
import { ButtonActionsFlag } from '../../actions/ButtonActionsFlag';
import { RecyclebinButtonActionFlag } from '../../actions/RecyclebinButtonActionFlag';
export default function ButtonGroup({ typeOfButtons }) {
    const ButtonActionsFlagReducer = useSelector(state => state.ButtonActionsFlagReducer);
    const DeleteMoreThanOneDocumentReducer = useSelector(state => state.DeleteMoreThanOneDocumentReducer);
    const SelectedCheckboxReducer = useSelector(state => state.SelectedCheckboxReducer);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [recycleBin, setRecycleBin] = useState(false);
    const [purge, setPurge] = useState(false);
    const [recycle, setRecycle] = useState(false);
    const [rename, setRename] = useState(false);
    const [upload, setUpload] = useState(true);
    const [data, setData] = useState([]);
    const [purgeDocumentActive, setPurgeDocumentActive] = useState(false);
    const [renameDocumentActive, setRenameDocumentActive] = useState(false);
    const ModalFlagReducer = useSelector(state => state.ModalFlagReducer);
    const RecycleBinButtonReducer = useSelector(state => state.RecycleBinButtonReducer);
    const [data1, setData1] = useState('');
    const uploadClickEvent = () => {
        navigate('/Upload');
    }

    useEffect(() => {
        if (ButtonActionsFlagReducer) {
            setRecycleBin(ButtonActionsFlagReducer.recycleBin);
            setRename(ButtonActionsFlagReducer.rename);
            setUpload(ButtonActionsFlagReducer.upload);
            setData(ButtonActionsFlagReducer.data);
        }
    }, [ButtonActionsFlagReducer]);

    useEffect(() => {
        if (RecycleBinButtonReducer) {
            
            setPurge(RecycleBinButtonReducer.purge);
            setRecycle(RecycleBinButtonReducer.recycle);
        }
    }, [RecycleBinButtonReducer])

    const DeleteMoreThanOne = (event) => {
        event.preventDefault();
        var ids = '';
        for (var a = 0; a < data.length; a++) {
            ids = ids + data[a].model.id + ",";
        }
        debugger;
        dispatch(DeleteMoreThanOneDocumentAction(ids));
    }
    useEffect(() => {
        if (DeleteMoreThanOneDocumentReducer && DeleteMoreThanOneDocumentReducer.data && DeleteMoreThanOneDocumentReducer.data.isSuccessful === true) {
            dispatch(IndexAction());
            dispatch(ButtonActionsFlag());
        }
    }, [DeleteMoreThanOneDocumentReducer]);

    useEffect(() => {
        if (SelectedCheckboxReducer && SelectedCheckboxReducer.data) {
            var ids = '';
            for (var a = 0; a < SelectedCheckboxReducer.data.length; a++) {
                ids = ids + SelectedCheckboxReducer.data[a].id + ",";
            }
            setData1(ids);
        }
    }, [SelectedCheckboxReducer]);

    useEffect(() => {
        dispatch(ButtonActionsFlag());
        dispatch(RecyclebinButtonActionFlag());
    },[])
    const renameClickEvent = (event) => {
        event.preventDefault();
        setRenameDocumentActive(true);
        dispatch(ShowModalFlag("edit"));
    }

    const onClickPurge = (event) => {
        event.preventDefault();
        setPurgeDocumentActive(true);
        dispatch(RecyclebinButtonActionFlag());
        dispatch(ShowModalFlag());
    }

    const onClickRecycle = (event) => {
        event.preventDefault();
        dispatch(RecyclebinButtonActionFlag());
        if (data1 !== '') {
            dispatch(UnDeleteMoreThanOne(data1));
        }
    }
    return (

        <>
            {(() => {
                switch (typeOfButtons) {
                    case 'recycleBin':
                        return <div className="btn-group ms-3" style={{ minWidth:'190px',maxWidth: '240px' }} role="group">
                            <button type="button" className="btn btn-recycle btn-inh" title="Send files or folders to trash" onClick={onClickRecycle} disabled={!recycle}>
                                <i style={{ fontSize: '25px' }} className="fa-solid fa-recycle"></i>
                                <span className="icon-text">Restore</span>
                            </button>
                            <button type="button" className="btn btn-purge btn-inh" title="Send files or folders to trash" onClick={onClickPurge} disabled={!purge}>
                                <i style={{ fontSize: '25px' }} className="fa-solid fa-trash-can"></i>
                                <span className="icon-text">Purge</span>
                            </button>
                        </div>
                    case 'myDocuments':
                        return <div className="btn-group ms-3" role="group" style={{minWidth: '275px'} }>
                            <button type="button" disabled={!upload} onClick={uploadClickEvent} className="play btn btn-new btn-inh btn-sm" title="Upload a new transcription">
                            <i className="fa fa-file-circle-plus" style={{ fontSize: '25px' }}></i>
                            <span className="icon-text">New PDF</span>
                        </button>
                            <button type="button" className="btn btn-rename btn-inh btn-sm" title="Rename a file or folder" onClick={renameClickEvent} disabled={!rename}>
                                <i style={{ fontSize: '25px' }} className="fa-regular fa-pen-to-square"></i>
                                <span className="icon-text">Edit PDF</span>
                            </button>
                            <button type="button" className="btn btn-recycle btn-inh btn-sm" title="Send files or folders to trash" disabled={!recycleBin} onClick={DeleteMoreThanOne}>
                                <i style={{ fontSize: '25px' }} className="fa-solid fa-trash-can"></i>
                                <span className="icon-text">Recycle Bin</span>
                            </button></div>
                    case 'sharedWithMe':
                        return <div></div>

                    default:
                        return <div></div>

                }
            })()}
            {renameDocumentActive && <Rename show="true" />}
            {purgeDocumentActive && <PurgeDocumentModal data={data1} show="true" />}
        </>
    )
}

/*


<button type="button" class="btn btn-inh" title="Create a new folder">
<i style={{ fontSize: '25px' }} className="fas fa-file"></i>
<span className="icon-text">New Folder</span>
</button>
<button type="button" class="btn btn-inh" title="Create a copy of a file" disabled="">
<i style={{ fontSize: '25px' }} className="fas fa-file-circle-plus"></i>
<span className="icon-text">Copy</span>
</button>
<button type="button" className="btn btn-inh" title="Move a file or folder" disabled="">
<i style={{ fontSize: '25px' }} class="fa-solid fa-arrow-right-arrow-left"></i>
<span className="icon-text">Move</span>
</button>

*/

/*

 {(() => {
                switch (typeOfButtons) {
                    case 'recycleBin':
                        return <div className="btn-group ms-3" style={{ maxWidth: '240px' }} role="group">
                            <button type="button" className="btn btn-inh" title="Send files or folders to trash" onClick={onClickRecycle} disabled={!recycle}>
                                <i style={{ fontSize: '25px' }} className="fa-solid fa-recycle"></i>
                                <span className="icon-text">Restore</span>
                            </button>
                            <button type="button" className="btn btn-inh" title="Send files or folders to trash" onClick={onClickPurge} disabled={!purge}>
                                <i style={{ fontSize: '25px' }} className="fa-solid fa-trash-can"></i>
                                <span className="icon-text">Purge</span>
                            </button>
                        </div>
                    case 'myDocuments':
                        return <div className="btn-group ms-3" role="group"><button type="button" disabled={!upload} onClick={uploadClickEvent} className="play btn btn-inh" title="Upload a new transcription">
                            <i className="fa fa-file-circle-plus" style={{ fontSize: '25px' }}></i>
                            <span className="icon-text">New PDF</span>
                        </button>
                            <button type="button" className="btn btn-inh" title="Rename a file or folder" onClick={renameClickEvent} disabled={!rename}>
                                <i style={{ fontSize: '25px' }} className="fa-regular fa-pen-to-square"></i>
                                <span className="icon-text">Edit PDF</span>
                            </button>
                            <button type="button" className="btn btn-inh" title="Send files or folders to trash" disabled={!recycleBin} onClick={DeleteMoreThanOne}>
                                <i style={{ fontSize: '25px' }} className="fa-solid fa-trash-can"></i>
                                <span className="icon-text">Recycle Bin</span>
                            </button></div>
                    case 'sharedWithMe':
                        return <div></div>

                    default:
                        return <div></div>

                }
            })()}
 */