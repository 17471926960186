import axios from 'axios';

export const RESENDEMAIL_SUCCESS = 'RESENDEMAIL_SUCCESS';
export const RESENDEMAIL_FAILURE = 'RESENDEMAIL_FAILURE';

export const sendVerificationEmail = (email) => async (dispatch) => {
    
    try {
        const inputEmail = {
            Email: email,
        }

        const response = await axios.post('/api/SignIn/SendVerificationEmail', inputEmail);
        //authenticated redirect home page
        dispatch({
            type: RESENDEMAIL_SUCCESS,
            payload: response.data,
        });

    } catch (errors) {
        //not authenticated give error
        dispatch({
            type: RESENDEMAIL_FAILURE,
            payload: errors,
        });

    };
}

