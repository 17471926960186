const initialState = {
    upload: true,
    rename: false,
    recycleBin: false,
    data: null,

};
export const ButtonActionsFlagReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'NOT_CHECKBOX':
            return {
                ...state,
                upload: true,
                rename: false,
                recycleBin: false,
                data: action.payload,
            };
        case 'ONE_CHECKBOX':
            return {
                ...state,
                upload: false,
                rename: true,
                recycleBin: true,
                data: action.payload,
            };
        case 'TWOORMORE_CHECKBOX':
            return {
                ...state,
                upload: false,
                rename: false,
                recycleBin: true,
                data: action.payload,
            };
        case 'CLEAR_STATE':
            return initialState;
        default:
            return state;
    }

}
export default ButtonActionsFlagReducer;