import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import $ from 'jquery';
import { Link, useLocation, useParams } from 'react-router-dom';
import { PasswordlessTokenGenerationAction } from '../../actions/LinkEntryAction';
import { LinkEntryAction } from '../../actions/LinkEntryAction';
import { useDispatch, useSelector } from 'react-redux';
import booby from '../../images/Logo_removebg.png';
import { useNavigate } from 'react-router-dom';
export default function LinkEntry() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [maigcEmail, setMagicEmail] = useState("");
    let { viewKey } = useParams();

    const uis = useSelector(s => s.getUserInformationReducer);

    const ler = useSelector(s => s.LinkEntryReducer);

    const PasswordlessTokenGenerationReducer = useSelector(state => state.PasswordlessTokenGenerationReducer);

    const [showOk, setShowOk] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        //const token = catpchaRef.current.getValue();
        //captchaRef.current.reset();
        dispatch(PasswordlessTokenGenerationAction(viewKey, maigcEmail));
    }

    useEffect(() => {
        dispatch(LinkEntryAction(viewKey))
    }, []);

    useEffect(() => {

        if (PasswordlessTokenGenerationReducer && PasswordlessTokenGenerationReducer.data && PasswordlessTokenGenerationReducer.data.isSuccessful === true) {
            setShowOk(true);            
        }
    }, [PasswordlessTokenGenerationReducer])


    useEffect(() => {

        console.log(uis, ler);
        if (uis != null && uis.data != null && uis.data.data.id != null) {            

            if (ler != null && ler.data != null && ler.data.data != null)
                navigate("/index/viewer/" + ler.data.data.document.id);
        }

    }, [uis, ler]);

    return (<>
        <div className="row AuthTL">
            <div className="col-md-4 d-flex justify-content-center">
                <section className="">
                    <img src={booby}></img>
                    <form id="account" method="post" onSubmit={handleSubmit}>
                        <h2>View Document {viewKey}</h2>

                        {showOk && <p>Please check your email for magic link...</p>}
                        {!showOk && 
                            <>Please either
                            <a className="btn btn-sm btn-primary m-2" href={'/login?ReturnUrl=%2Findex%2Fviewer%2F' + viewKey}>login</a>
                            to your account
                            <br /><span className=" m-2">or</span>
                            <br /><span className=" m-2">Enter your email address</span>
                            <input type="email" id="magicEmailid" name="magicEmailid" className="form-control" value={maigcEmail} onChange={e => setMagicEmail(e.target.value)} />
                            and <button className="btn btn-sm btn-primary m-2" type="submit"> submit </button> magic link to access file.
                        </>
                        }
                    </form>
                </section>
            </div>
        </div>
    </>)
}