import React, { Component } from 'react';
import { Outlet } from 'react-router';
export class Footer extends Component {

    render() {
        return (
            <div className="footer">
                <span style={{ color: "white" }} className="text-center">support@bymmy.com&copy; 2023 - Bymmy Share</span>
            </div>
        );
    }
}
