import axios from 'axios';

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE';



export const SignInAction = (email, password, validationPassword, firstName,lastName) => async (dispatch) => { 
    const newUser = {
        Email: email,
        Password: password,
        ConfirmPassword: validationPassword,
        FirstName: firstName,
        lastName: lastName
    };



    await axios.post('/api/SignIn/Register', newUser, JSON).then(response => {
        dispatch({
            type: SIGNIN_SUCCESS,
            payload: response.data,
        });
    }).catch(errors => {
        //not sigın give error
        dispatch({
            type: SIGNIN_FAILURE,
            payload: errors.response.data,
        });
    });
}

