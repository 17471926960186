import React, { createRef, forwardRef, useState } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";


function Modal(props) {
    if (!props.show) {
        return null;
    }

    /*const closeOnEscape = (e) => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    }

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscape)
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscape)
        }
    },[])*/
    return (
        <>
            <div className="modal no-border" onClick={props.onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()} style={props.styleProps}>
                    <div className="modal-header" style={props.styleHeaderProps }>
                        <div className="modal-title title-statistic">{props.titleProps}</div>
                        <div>
                            <i onClick={props.onClose} style={{ fontSize: '17px'}}  className="fa-solid fa-xmark"></i>
                        </div>
                    </div>
                    <div className="modal-body">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );

}
export default Modal;
/*
 <div className="modal-footer">
                        <button onClick={props.onClose}  className="btn btn-secondary m-1 lift">Close</button>
                        <button onClick={props.onClose}  className="btn btn-primary m-1 sharenow">Yes</button>
                    </div>
                    */